<template>
  <table class="table table-bordered">
    <thead class="table-bordered">
      <tr>
        <th
          v-for="(th, key) in thList"
          :key="`th-${key}`"
          :style="`width: ${th.width};`"
          :class="{ 'cursor-pointer': th.sortBy }"
          class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1"
          @click="th.sortBy ? chageSortEmit(th.sortBy) : ''"
        >
          <div class="d-flex justify-content-between">
            {{ $t(th.i18KeyLabel) }}
            <div v-if="th.sortBy" class="d-flex flex-column text-gray position-relative">
              <font-awesome-icon
                :icon="['fas', 'sort-up']"
                class="fa-w-10"
                :class="{ 'text-black': sortedUp(th.sortBy) }"
              ></font-awesome-icon>
              <font-awesome-icon
                :icon="['fas', 'sort-down']"
                class="fa-w-10"
                :class="{ 'text-black': sortedDown(th.sortBy) }"
              ></font-awesome-icon>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, key) in items" :key="`service-${key}`">
        <td class="py-1 px-1-5">{{ item.client_name }}</td>

        <td class="py-0-7 px-1-5">{{ item.phone || '-' }}</td>
        <td class="py-0-7 px-1-5">{{ item.email }}</td>

        <td class="py-0-7 px-1-5">CHF {{ item.amount }}</td>
        <td class="py-0-7 px-2">
          <div
            class="cursor-pointer d-flex flex-row justify-content-center align-items-center"
            style="color: #8d9fa7;border: 0.5px solid #e0e0e0; width: 30px; height: 30px; border-radius: 3px;"
            @click="viewDetailedInfoPage(item.id)"
          >
            <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SalonClientsTable',
  props: {
    items: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      sortedBy: '',
      thList: [
        {
          i18KeyLabel: 'salon-clients.table-label.client_name',
          sortBy: 'client_name',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-clients.table-label.phone',
          sortBy: 'phone',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-clients.table-label.email',
          sortBy: 'email',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-clients.table-label.order_amount',
          sortBy: 'amount',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-clients.table-label.action',
          width: '76px'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  methods: {
    sortedUp(val) {
      return this.sortedBy === val
    },
    sortedDown(val) {
      return this.sortedBy === `-${val}`
    },
    chageSortEmit(val) {
      if (this.sortedBy === val) val = `-${val}`

      this.sortedBy = val
      this.$emit('change-sort', val)
    },
    viewDetailedInfoPage(clientId) {
      this.$emit('view-profile', clientId)
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
</style>
