<template>
  <main class="navbar-offset-tablet salon-info-background full-page-height">
    <PageNavigator :page-title="$t('salon-clients.page-title')" :has-back="false" />
    <div v-if="!isClientsDataLoading" class="px-1-5 pt-7-5 salon-flow">
      <SalonClientsTable :items="clients.data" @change-sort="changeSort" @view-profile="getProfileInfo" />
    </div>
    <div v-if="!isClientsDataLoading" class="px-1-5 pt-5-5 salon-flow">
      <CustomPagination
        :data="clients"
        :show-disabled="true"
        @pagination-change-page="changePaginationPage"
      ></CustomPagination>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import SalonClientsTable from '@/components/salon/SalonClientsTable'
import CustomPagination from '@/components/CustomPagination'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SalonClients',
  components: { PageNavigator, SalonClientsTable, CustomPagination },
  data() {
    return {
      clientsListParams: {
        sort: '-amount',
        page: 1
      },
      isClientsDataLoading: false
    }
  },
  computed: {
    ...mapGetters({
      clients: 'salonClients/getClients',
      accountInfo: 'user/getAccountInfo'
    }),
    salonId() {
      return this.accountInfo.salon_id
    }
  },
  mounted() {
    this.getClients({ ...this.clientsListParams, salon_id: this.salonId })
  },
  methods: {
    ...mapActions({
      getClients: 'salonClients/getClients',
      getClientsProfileInfo: 'salonClients/getClientsProfileInfo',
      setError: 'userMessages/setError'
    }),
    changePaginationPage(page) {
      this.clientsListParams.page = page
      this.getClientsList()
    },
    changeSort(newSortVal) {
      this.clientsListParams.sort = newSortVal
      this.getClientsList()
    },
    getClientsList() {
      this.isClientsDataLoading = true

      return this.getClients({ salon_id: this.salonId, ...this.clientsListParams })
        .then(() => {
          this.isClientsDataLoading = false
        })
        .catch(() => {
          this.isClientsDataLoading = false
          this.setError({
            type: 'get_detailed_info_error',
            messageI18Key: 'common.alerts.actions.global_error'
          })
        })
    },
    getProfileInfo(clientId) {
      this.$router.push(`/salon/clients/${clientId}`)
    }
  }
}
</script>
